.card {
  margin-top: 20px;
}

.timeline {
  margin: 20px 40px 40px 40px;
}

.show-bullets {
  list-style: circle;
}
